<template>
    <el-container>
        <el-aside width="240px">
            <LayoutAside></LayoutAside>
        </el-aside>
        <el-container>
            <el-header>
                <LayoutHeader></LayoutHeader>
            </el-header>
            <el-main>
                <router-view :key="$route.fullPath"></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import LayoutAside from "./LayoutAside.vue"
import LayoutHeader from "./LayoutHeader.vue"

export default {
    name: "Layout",
    components: {
        LayoutAside,
        LayoutHeader
    }
}
</script>

<style lang="scss" scoped>
.el-aside {
    background: #191a23;
}

.el-header {
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(#000000, 0.1);
}

.el-main {
    .breadcrumb {
        margin-bottom: 20px;
    }
}
</style>