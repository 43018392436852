<template>
    <div class="layout-aside">
        <div class="logo">
            <img draggable="false" src="../../assets/img/logo.png">
        </div>
        <el-menu
            :default-active="$route.path"
            background-color="#191a23"
            default-active="2"
            router
            text-color="#ffffff"
        >
            <el-submenu index="1">
                <template #title>
                    <i class="el-icon-s-check"></i>
                    <span>行政人事</span>
                </template>
                <el-menu-item index="/user/role/list">职位管理</el-menu-item>
                <el-menu-item index="/user/member/list">员工管理</el-menu-item>
            </el-submenu>
            <el-submenu index="2">
                <template #title>
                    <i class="el-icon-menu"></i>
                    <span>任务管理</span>
                </template>
                <el-menu-item index="/task/cpic/list">太保工单</el-menu-item>
                <el-menu-item index="/task/ecif/list">国寿工单</el-menu-item>
                <!--<el-menu-item @click="developMessage()">预约单</el-menu-item>-->
            </el-submenu>
            <el-submenu index="3">
                <template #title>
                    <i class="el-icon-truck"></i>
                    <span>车辆管理</span>
                </template>
                <el-menu-item index="/car/list">车辆管理</el-menu-item>
            </el-submenu>
            <el-submenu index="4">
                <template #title>
                    <i class="el-icon-user-solid"></i>
                    <span>技师管理</span>
                </template>
                <el-menu-item index="/technician/list">技师管理</el-menu-item>
            </el-submenu>
            <el-submenu index="5">
                <template #title>
                    <i class="el-icon-location"></i>
                    <span>定位管理</span>
                </template>
                <el-menu-item index="/location/list">定位管理</el-menu-item>
            </el-submenu>
            <el-submenu index="6">
                <template #title>
                    <i class="el-icon-chat-line-round"></i>
                    <span>评论管理</span>
                </template>
                <el-menu-item @click="developMessage()">评论管理</el-menu-item>
            </el-submenu>
            <el-submenu index="7">
                <template #title>
                    <i class="el-icon-wallet"></i>
                    <span>费用结算</span>
                </template>
                <el-menu-item index="/fee/list/cpic">太保工单</el-menu-item>
                <el-menu-item index="/fee/list/ecif">国寿工单</el-menu-item>
            </el-submenu>
            <el-submenu index="8">
                <template #title>
                    <i class="el-icon-s-tools"></i>
                    <span>系统设置</span>
                </template>
                <el-menu-item @click="developMessage()">系统日志</el-menu-item>
                <el-menu-item @click="developMessage()">图片设置</el-menu-item>
                <el-menu-item @click="developMessage()">系统参数</el-menu-item>
            </el-submenu>
        </el-menu>
    </div>
</template>

<script>
import {ElMessage} from "element-plus";

export default {
    name: "LayoutAside",
    setup(){
        let developMessage = ()=>{
            ElMessage({
                message: "暂未开放，敬请期待！",
                type: "error"
            });
        }

        return {
            developMessage
        }
    }
}
</script>

<style lang="scss" scoped>
.layout-aside {
    .logo {
        position: relative;
        display: block;
        width: 100%;
        height: 60px;
        padding: 5px 0;
        text-align: center;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .el-menu {
        height: calc(100vh - 60px);
        border-right: none !important;
    }
}
</style>