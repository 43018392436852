<template>
    <header class="layout-header">
        <div class="header-wrapper">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <template v-for="(item,index) in $route.meta.breadcrumb">
                    <el-breadcrumb-item>{{ item }}</el-breadcrumb-item>
                </template>
            </el-breadcrumb>
            <div class="header-tool">
                <a class="download" href="https://jiuyuan.demo.qoomoe.com/app.apk">APP下载</a>
<!--                <el-dropdown placement="bottom" trigger="hover">-->
<!--                    <div class="message">-->
<!--                        <el-badge is-dot>-->
<!--                            <i class="el-icon-bell"></i>-->
<!--                        </el-badge>-->
<!--                    </div>-->
<!--                    <template #dropdown>-->
<!--                        <el-dropdown-menu>-->
<!--                            <el-dropdown-item>平板拖车-苏AJ8178 剩余12天</el-dropdown-item>-->
<!--                            <el-dropdown-item>平板拖车-苏AJ8115 剩余12天</el-dropdown-item>-->
<!--                            <el-dropdown-item>小型救援车-苏A2ZG89 剩余12天</el-dropdown-item>-->
<!--                            <el-dropdown-item>小型救援车-苏A21JE6 剩余12天</el-dropdown-item>-->
<!--                            <el-dropdown-item>小型救援车-苏A60KQ5 剩余12天</el-dropdown-item>-->
<!--                        </el-dropdown-menu>-->
<!--                    </template>-->
<!--                </el-dropdown>-->
                <el-dropdown placement="bottom" trigger="hover">
                    <div class="user">
                        <el-avatar :src="mockAvatar" class="user-avatar" size="small"></el-avatar>
                        <p class="user-name">{{ $store.getters.getUserData.username }}</p>
                    </div>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item icon="el-icon-setting">设置</el-dropdown-item>
<!--                            <el-dropdown-item icon="el-icon-user">个人中心</el-dropdown-item>-->
                            <el-dropdown-item
                                divided
                                icon="el-icon-switch-button"
                                @click.native="logout()"
                            >
                                退出登录
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
        </div>
    </header>
</template>

<script>
import {reactive, toRefs} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

export default {
    name: "LayoutHeader",
    setup() {
        let $store = useStore()
        let $router = useRouter()

        let state = reactive({
            mockAvatar: "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
        })

        let logout = () => {
            $store.commit("delToken")
            $store.commit("delUserData")
            $router.push({
                name: "AuthLogin"
            })
        }

        return {
            ...toRefs(state),
            logout
        }
    }
};
</script>

<style lang="scss" scoped>
.layout-header {
    width: 100%;
    height: 100%;

    .header-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;

        .header-tool {
            display: flex;
            align-items: center;
            margin-left: auto;

            .download {
                margin-right: 20px;
                font-size: 14px;
                color: #333333;
                cursor: pointer;
                transition: all 0.2s ease;

                &:hover {
                    color: #11ACFA;
                }
            }

            .message {
                cursor: pointer;

                i {
                    font-size: 20px;
                    color: #333333;
                }
            }

            .user {
                margin-left: 20px;
                cursor: pointer;
                font-size: 0;

                .user-avatar {
                    display: inline-block;
                    vertical-align: middle;
                }

                .user-name {
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 10px;
                    font-size: 14px;
                    color: #333333;
                }
            }
        }
    }
}
</style>